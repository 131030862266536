// ==============================
// * PAGE DESIGNER: LAYOUT
// Extends app_storefront_base
// ? This file gathers all the custom layout CSS.
// ==============================

@import "base/experience/components/commerceLayouts/layout";

.section {
    padding-top: 80px;
    padding-bottom: 80px;
}
